import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, combineLatest, map, of } from "rxjs";
import {
    MaintainIndicativeRateRequest,
    IndicativeRateResponse,
    NonDeliveryCurrencyResponse,
    UpdateCurNonDeliveryRequest,
    indicativeRateHistoryRequest,
    indicativeRateHistoryResponse,
    ValidateIndicativeRateRequest,
    ValidateIndicativeRateResponse,
    UploadIndicativeRateRequest,
    UploadIndicativeRateResponse,
} from "../models/indicative-rate.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { APIUrlToUse } from "../../../../enums/api-url-to-use.enum";
import { BaseService, ServiceName } from "../../../../services/base-service";
import { APIResponse } from "../../../common/models/api-response.model";
import { CommonDropdownService } from "../../../common/services/common-dropdown.service";
import { CurrencyDropdownResponse, CurrencyHistoryDropdownResponse } from "../../../common/models/dropdown.model";
import { HistroyIndicativeRateDropdown, MaintainIndicativeRateDropdown } from "../models/dropdown.model";
import { AlertService } from "../../../../services/utilities/alert.service";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
    providedIn: "root",
})
export class IndicativeRateService extends BaseService {
    constructor(
        private http: HttpClient,
        private commonDropdownService: CommonDropdownService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService,
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3000", ServiceName.fwd);
    }

    private selectedMainCurrencySubject = new BehaviorSubject<string | null>(null);
    selectedMainCurrencyAction$ = this.selectedMainCurrencySubject.asObservable();
    private selectedConvertCurrencySubject = new BehaviorSubject<string | null>(null);
    selectedConvertCurrencyAction$ = this.selectedConvertCurrencySubject.asObservable();
    onSelectMainCurrency(selectedCode: string | null): void {
        this.selectedMainCurrencySubject.next(selectedCode);
    }
    onSelectConvertCurrency(selectedCode: string | null): void {
        this.selectedConvertCurrencySubject.next(selectedCode);
    }
    onResetCurrencyDropdown(): void {
        this.selectedMainCurrencySubject.next(null);
        this.selectedConvertCurrencySubject.next(null);
    }
    currency$: Observable<APIResponse<CurrencyDropdownResponse[]>> = this.commonDropdownService.getCurrency();

    private selectedMainCurrencyHistorySubject = new BehaviorSubject<string | null>(null);
    selectedMainCurrencyHistoryAction$ = this.selectedMainCurrencyHistorySubject.asObservable();
    private selectedConvertCurrencyHistorySubject = new BehaviorSubject<string | null>(null);
    selectedConvertCurrencyHistoryAction$ = this.selectedConvertCurrencyHistorySubject.asObservable();
    onSelectMainCurrencyHistory(selectedCode: string | null): void {
        this.selectedMainCurrencyHistorySubject.next(selectedCode);
    }
    onSelectConvertCurrencyHistory(selectedCode: string | null): void {
        this.selectedConvertCurrencyHistorySubject.next(selectedCode);
    }
    onResetCurrencyHistoryDropdown(): void {
        this.selectedMainCurrencyHistorySubject.next(null);
        this.selectedConvertCurrencyHistorySubject.next(null);
    }

    maincurrencyhistory$: Observable<APIResponse<CurrencyHistoryDropdownResponse[]>> =
        this.commonDropdownService.getMainCurrencyHistory();
    convcurrencyhistory$: Observable<APIResponse<CurrencyHistoryDropdownResponse[]>> =
        this.commonDropdownService.getConvCurrencyHistory();

    private readonly url = {
        getIndicativeRate: "/indicative-rate",
        getReverseIndicativeRate: "/indicative-rate/reverse",
        maintainIndicativeRate: "/indicative-rate/maintain",
        curNonDelivery: "/cur-non-delivery/",
        indicativeRateHistory: "/indicative-rate/history",
        validateIndicativeRate: "/indicative-rate/validate",
        uploadIndicativeRate: "/indicative-rate/upload",
        distributeIndicativeRate: "/indicative-rate/distribute-indicative-rate",
    };

    private get getIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.getIndicativeRate);
    }
    private get getReverseIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.getReverseIndicativeRate);
    }
    private get maintainIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.maintainIndicativeRate);
    }
    private get curNonDeliveryUrl(): string {
        return this.prepareUrl(this.url.curNonDelivery);
    }
    private get indicativeRateHistoryUrl(): string {
        return this.prepareUrl(this.url.indicativeRateHistory);
    }
    private get validateIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.validateIndicativeRate);
    }
    private get uploadIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.uploadIndicativeRate);
    }
    private get distributeIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.distributeIndicativeRate);
    }

    getIndicativeRate(): Observable<APIResponse<IndicativeRateResponse[]>> {
        return this.http.get<APIResponse<IndicativeRateResponse[]>>(this.getIndicativeRateUrl);
    }

    getReverseIndicativeRate(): Observable<APIResponse<IndicativeRateResponse[]>> {
        return this.http.get<APIResponse<IndicativeRateResponse[]>>(this.getReverseIndicativeRateUrl);
    }

    maintainIndicativeRate(body: MaintainIndicativeRateRequest): Observable<APIResponse<IndicativeRateResponse>> {
        return this.http.post<APIResponse<IndicativeRateResponse>>(this.maintainIndicativeRateUrl, body);
    }

    getMaintainIndicativeDropdown(): Observable<MaintainIndicativeRateDropdown> {
        this.spinner.show();
        return combineLatest({
            currency: this.currency$,
            selectedMainCurrenct: this.selectedMainCurrencyAction$,
            selectedConvertCurrency: this.selectedConvertCurrencyAction$,
        }).pipe(
            map((v) => {
                this.spinner.hide();
                return {
                    currency: v.currency.data,
                    mainCurrency: v.currency.data.filter((d) => d.currency_code !== v.selectedConvertCurrency),
                    convertCurrency: v.currency.data.filter((d) => d.currency_code !== v.selectedMainCurrenct),
                };
            }),
            catchError((err: HttpErrorResponse) => {
                this.spinner.hide();
                this.alertService.technicalError(err?.error?.message);
                return of({
                    currency: [],
                    mainCurrency: [],
                    convertCurrency: [],
                });
            }),
        );
    }

    getCurNonDelivery(): Observable<APIResponse<NonDeliveryCurrencyResponse[]>> {
        return this.http.get<APIResponse<NonDeliveryCurrencyResponse[]>>(this.curNonDeliveryUrl);
    }

    updateCurNonDelivery(currency: string, body: UpdateCurNonDeliveryRequest): Observable<APIResponse<null>> {
        return this.http.patch<APIResponse<null>>(this.curNonDeliveryUrl + currency, body);
    }

    indicativeRateHistory(body: indicativeRateHistoryRequest): Observable<APIResponse<indicativeRateHistoryResponse[]>> {
        return this.http.post<APIResponse<indicativeRateHistoryResponse[]>>(this.indicativeRateHistoryUrl, body);
    }

    validateIndicativeRate(body: ValidateIndicativeRateRequest): Observable<APIResponse<ValidateIndicativeRateResponse>> {
        return this.http.post<APIResponse<ValidateIndicativeRateResponse>>(this.validateIndicativeRateUrl, body);
    }

    uploadIndicativeRate(body: UploadIndicativeRateRequest): Observable<APIResponse<UploadIndicativeRateResponse>> {
        return this.http.post<APIResponse<UploadIndicativeRateResponse>>(this.uploadIndicativeRateUrl, body);
    }

    distributeIndicativeRate(): Observable<APIResponse<boolean>> {
        return this.http.post<APIResponse<boolean>>(this.distributeIndicativeRateUrl, null);
    }

    getHistoryIndicativeDropdown(): Observable<HistroyIndicativeRateDropdown> {
        this.spinner.show();
        return combineLatest({
            mainCurrency: this.maincurrencyhistory$,
            convCurrency: this.convcurrencyhistory$,
            selectedMainCurrency: this.selectedMainCurrencyHistoryAction$,
            selectedConvertCurrency: this.selectedConvertCurrencyHistoryAction$,
        }).pipe(
            map((v) => {
                this.spinner.hide();
                return {
                    mainCurrency: v.mainCurrency.data.filter((d) => d.currency_code !== v.selectedConvertCurrency),
                    convertCurrency: v.convCurrency.data.filter((d) => d.currency_code !== v.selectedMainCurrency),
                };
            }),
            catchError((err: HttpErrorResponse) => {
                this.spinner.hide();
                this.alertService.technicalError(err?.error?.message);
                return of({
                    mainCurrency: [],
                    convertCurrency: [],
                });
            }),
        );
    }
}
