import { AfterViewInit, Component } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
    GetFwoResponse,
    GetFwoResponseActivityLog,
} from "../../../../../api/fwd/fwd-request/create-fwo-request/models/get-fwo-response.model";
import { FWO_STATUS } from "../../../../../constant/fwo-status.constant";
import { DIRECTION } from "../../../../../enums/direction.enum";

@Component({
    selector: "app-print-request",
    templateUrl: "./print-request.component.html",
    styleUrl: "./print-request.component.scss",
})
export class PrintRequestComponent implements AfterViewInit {
    dataSource!: MatTableDataSource<GetFwoResponseActivityLog>;
    displayedColumns: string[] = ["comment_by", "role", "action", "comment", "comment_date"];
    requestData!: GetFwoResponse;
    fwoStatus = FWO_STATUS;

    constructor() {
        let _this = this;
        window.addEventListener(
            "message",
            function (event) {
                if (typeof event.data == "object" && event.data.call == "sendFWOData") {
                    _this.requestData = event.data.value;
                    _this.dataSource = new MatTableDataSource(_this.requestData.activity_log);
                    window.parent.postMessage("dataPrintFWOPrepared", "*");
                }
            },
            false,
        );
    }

    ngAfterViewInit() {}

    get direction() {
        switch (this.requestData.fwo_request.direction_id) {
            case DIRECTION.IMPORT:
                return "Import";
            case DIRECTION.EXPORT:
                return "Export";
            case DIRECTION.OFFSHORE:
                return "Offshore";
            default:
                return "Offshore";
        }
    }
}
