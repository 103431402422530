<div class="preview-print" *ngIf="requestData">
    <div class="w-full flex justify-center">
        <h1>e-Form : FWD w/o Underlying Request</h1>
    </div>
    <br>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Request Form Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="grid grid-cols-12 gap-2 w-full mt-2">
                <p class="font-bold col-span-2">Request Form No. :</p>
                <div class="col-span-7">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.request_form_information.request_no">
                    </mat-form-field>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2 w-full">
                <p class="font-bold col-span-2">Request Date :</p>
                <div class="col-span-7">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.request_form_information.request_date | date: 'dd/MM/yyyy'">
                    </mat-form-field>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2 w-full">
                <p class="font-bold col-span-2">Request Status :</p>
                <div class="col-span-7">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="fwoStatus[requestData.request_form_information.status_id]">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Requester Information</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="grid grid-cols-12 gap-2 w-full mt-2">
                <p class="font-bold col-span-2">Requester Name :</p>
                <div class="col-span-7">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.requester_information.requester_name">
                    </mat-form-field>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2 w-full">
                <p class="font-bold col-span-2">Company Code :</p>
                <div class="col-span-2">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.requester_information.requester_com_code">
                    </mat-form-field>
                </div>
                <p class="font-bold">Company:</p>
                <div class="col-span-7">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.requester_information.requester_com_name">
                    </mat-form-field>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2 w-full">
                <p class="font-bold col-span-2">Department :</p>
                <div class="col-span-3">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.requester_information.requester_dept">
                    </mat-form-field>
                </div>
                <p class="font-bold">Division :</p>
                <div class="col-span-3">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.requester_information.requester_division">
                    </mat-form-field>
                </div>
                <p class="font-bold">Country :</p>
                <div class="col-span-2">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.requester_information.requester_country">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-header>
            <mat-card-title>FWO Request</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="grid grid-cols-12 gap-2 w-full mt-2">
                <p class="font-bold col-span-2">Direction :</p>
                <div class="col-span-3">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="direction">
                    </mat-form-field>
                </div>
                <p class="font-bold">Currency:</p>
                <div class="col-span-2">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.fwo_request.currency_code">
                    </mat-form-field>
                </div>
                <p class="font-bold">Product :</p>
                <div class="col-span-3">
                    <mat-form-field appearance="outline" class="w-full">
                        <input type="text" matInput readonly [value]="requestData.fwo_request.product">
                    </mat-form-field>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2 w-full">
                <p class="font-bold col-span-2">Objective :</p>
                <div class="col-span-10">
                    <mat-form-field appearance="outline" class="w-full">
                        <textarea matInput readonly [value]="requestData.fwo_request.objective"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    @for (underlying of requestData.detail; track underlying) {
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{underlying.req_type === '01' ? 'Import' : 'Export'}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="grid grid-cols-12 gap-2 w-full mt-2">
                    <p class="font-bold col-span-3">Assign To :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.assign_to_name">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full mt-2">
                    <p class="font-bold col-span-3">Indicative Rate :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.indi_rate">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">Approval Rate :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.approval_rate">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">Request Amount :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.request_amount">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">{{underlying.req_type === '01' ? 'Supplier' : 'Customer'}} Name :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.supplier_or_customer_name">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">Estimate Payment Date :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.estimated_payment_or_receive_date | date: 'dd/MM/yyyy'">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">Bank :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.bank_name">
                        </mat-form-field>
                        <!-- <mat-radio-group aria-labelledby="bank-radio-group-label" class="bank-radio-group"
                            [ariaReadOnly]="true">
                            <mat-radio-button class="bank-radio-button w-full" [value]="'BNK00338'">Siam Commercial
                                Bank</mat-radio-button>
                            <mat-radio-button class="bank-radio-button w-full" [value]="'other'">
                                <div class="flex">
                                    <span class="text-nowrap mr-2 self-center">Other Bank</span>
                                    <mat-form-field appearance="outline" class="w-full">
                                        <input type="text" matInput readonly [value]="underlying.bank_name">
                                    </mat-form-field>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group> -->
                    </div>
                </div>
                <div *ngIf="underlying.reason" class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">Reason of other bank :</p>
                    <div class="col-span-6">
                        <mat-form-field appearance="outline" class="w-full">
                            <input type="text" matInput readonly [value]="underlying.reason">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2 w-full">
                    <p class="font-bold col-span-3">Remark :</p>
                    <div class="col-span-9">
                        <mat-form-field appearance="outline" class="w-full">
                            <textarea matInput readonly [value]="underlying.remark"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <br>
    }
    <mat-card>
        <mat-card-header>
            <mat-card-title>Action Log & Comment</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Information : FWD w/o Underlying has approval line start as 1. Section Manager 2. Bu Manager 3. Finance
                Manager 4. Bu Division</p>
            <div class="w-full">
                <table mat-table [dataSource]="dataSource">
                    <!-- Comment By Column -->
                    <ng-container matColumnDef="comment_by">
                        <th mat-header-cell *matHeaderCellDef>Comment By</th>
                        <td mat-cell *matCellDef="let row">{{ row.comment_by }}</td>
                    </ng-container>

                    <!-- Role Column -->
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>Role</th>
                        <td mat-cell *matCellDef="let row">{{ row.role_name }}</td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            Action
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.action_name }}</td>
                    </ng-container>

                    <!-- Comment Column -->
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef>Comment</th>
                        <td mat-cell *matCellDef="let row">{{ row.comment_text }}</td>
                    </ng-container>

                    <!-- Comment Date Column -->
                    <ng-container matColumnDef="comment_date">
                        <th mat-header-cell *matHeaderCellDef>
                            Comment Date
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.comment_date | date: 'dd/MM/yyyy HH:mm' }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5">No data found.</td>
                    </tr>
                </table>
            </div>
            <!-- <div class="grid grid-cols-12 gap-2 w-full mt-5">
                <p class="font-bold col-span-3">Comment :</p>
                <div class="col-span-9">
                    <mat-form-field appearance="outline" class="w-full">
                        <textarea matInput readonly [value]="requestData.comment_text"></textarea>
                    </mat-form-field>
                </div>
            </div> -->
        </mat-card-content>
    </mat-card>
</div>